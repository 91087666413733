.site-footer--top {
  .grid-container--6 {
    .grid--item:nth-of-type(6n + 1) {
      width: 14.66667%;
      margin-left: 14.5%;
    }
  }
}

body.device-pc {
  .select2-container {
    .select2-choice {
      .select2-chosen {
        margin-left: 5px !important;
      }
    }
  }
}
